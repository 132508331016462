.header {
  width: 100%;
  position: fixed;
  background-color: var(--bg);
  display: flex;
  align-items: center;
  padding: var(--space-sm);
  overflow: hidden;
  border-bottom: 1px solid var(--lightgrey);
  z-index: 11;
  height: 4.5rem;
}

.header > * {
  margin-right: var(--space-xs);
}

.header > *:first-of-type {
  margin-right: var(--space-sm);
}

.header > *:last-of-type {
  margin-right: 0;
}

.logoContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.logoContainer img {
  height: 2.5rem;
}

.storeContainer {
  overflow: hidden;
  display: flex;
  align-items: center;
}

.store {
  font-size: var(--s3);
  font-weight: var(--font-weight-bold);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.store.alert {
  color: var(--danger);
  animation: blinkingText 1.5s infinite;
}

@keyframes blinkingText {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
}

.menuButton {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  border: 3px solid var(--black-lighter);
}

.menuButton.first {
  margin-left: auto;
}

.menuButton a {
  display: block;
}

.menuButton svg {
  padding: var(--space-xxs);
  width: 2.5rem;
  height: 2.5rem;
}

.menuButton svg path {
  fill: var(--text);
}

.menuButton span {
  padding: var(--space-xxs);
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: var(--s3);
  font-weight: var(--font-weight-bold);
}

.header a,
.header svg {
  height: 2.5rem;
}

.toggleThemeIcon {
  display: flex;
  align-items: center;
  margin-left: auto;
  padding-right: 0.25rem;
}
