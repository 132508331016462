:root {
  --font: 'DMSans', Arial, sans-serif;
  --font2: 'Signifier', Arial, sans-serif;
  --font-receipt: 'Fake Receipt', sans-serif;
  --font-weight-normal: 400;
  --font-weight-bold: 500;
  --font-normal: 16px;

  /* Font sizes */
  --s1: 2rem; /* 32px */
  --s2: 1.75rem; /* 28px */
  --s3: 1.5rem; /* 24px */
  --s4: 1.25rem; /* 20px */
  --s5: 1.125rem; /* 18px */
  --s6: 1rem; /* 16px */
  --s7: 0.875rem; /* 14px */
  --s8: 0.75rem; /* 12px */

  --lime: #e7eb5d;
  --lime-darker: hsl(62, 78%, 54%);
  --lime-lighter: hsl(62, 78%, 74%);
  --lime-1: var(--lime);
  --lime-2: hsl(62, 78%, 75%);
  --lime-3: hsl(62, 78%, 35%);
  --lime-4: hsl(62, 78%, 85%);

  --fucales: #72723b;
  --fucales-darker: hsl(60, 32%, 24%);
  --fucales-lighter: hsl(60, 32%, 60%);
  --fucales-1: var(--fucales);
  --fucales-2: hsl(60, 32%, 75%);
  --fucales-3: hsl(60, 32%, 60%);
  --fucales-4: hsl(60, 32%, 40%);

  --carrot: #ff8736;
  --carrot-darker: hsl(24, 100%, 51%);
  --carrot-lighter: hsl(24, 100%, 71%);
  --carrot-lightest: hsl(24, 100%, 81%);
  --carrot-1: var(--carrot);
  --carrot-2: hsl(24, 100%, 75%);
  --carrot-3: hsl(24, 100%, 35%);
  --carrot-4: hsl(24, 100%, 85%);

  --beige: #e9d7c9;
  --beige-darker: hsl(26, 42%, 75%);
  --beige-lighter: hsl(26, 42%, 95%);
  --beige-1: var(--beige);
  --beige-2: hsl(26, 42%, 75%);
  --beige-3: hsl(26, 42%, 60%);
  --beige-4: hsl(26, 42%, 35%);

  --black: #000;
  --black-lighter: #555;
  --black-1: var(--black);
  --black-2: hsl(0, 0%, 75%);
  --black-3: hsl(0, 0%, 60%);
  --black-4: hsl(0, 0%, 35%);

  --lightgrey-darker: hsl(0, 24%, 90%);
  --lightgrey: #f7f2f2;
  --lightgrey-1: var(--lightgrey);
  --lightgrey-2: hsl(0, 24%, 75%);
  --lightgrey-3: hsl(0, 24%, 60%);
  --lightgrey-4: hsl(0, 24%, 35%);

  --realgrey: #f2f2f2;
  --realgrey-darker: #f0f0f0;
  --realgrey-darkest: #828282;

  /* --success: #f3e24a;
  --warning: #f3b94a;
  --danger: #fe7575; */
  /* --success: #5DEB6B;
  --warning: var(--lime);
  --danger: #EB5D5D; */
  --success: #69ccbb;
  --warning: #f0e16b;
  --danger: #f07865;

  --bg: #fff;
  --bg-lighter: var(--bg);
  --text: #222;
  --text-black: #222;
  --border-color: var(--lightgrey);
  --border-radius: 0.5rem;
  --border-radius-sm: 0.35rem;
  --border-radius-round: 100%;
  --box-shadow-color: rgba(0, 0, 0, 0.15);
  --box-shadow-color-lighter: rgba(0, 0, 0, 0.08);
  --box-shadow: 0 5px 5px var(--box-shadow-color);

  --content-padding: 1.5rem;
  --space-xl: 4rem;
  --space-lg: 3rem;
  --space-md: 2rem;
  --space-sm2: 1.5rem;
  --space-sm: 1rem;
  --space-xs: 0.5rem;
  --space-xxs: 0.25rem;
  --elem-border-color: var(--beige);

  --btn-primary-color: var(--text-black);
  --btn-primary-bg: var(--lime);
  --btn-secondary-color: var(--text-black);
  --btn-secondary-bg: var(--beige);
  --btn-third-color: var(--text-black);
  --btn-third-bg: var(--fucales-lighter);
  --btn-fourth-color: var(--text-black);
  --btn-fourth-bg: var(--beige);
  --btn-fifth-color: var(--text-black);
  --btn-fifth-bg: var(--realgrey-lighter);
  --btn-clear-color: var(--text);
  --btn-clear-bg: transparent;
  --btn-padding: 1rem;
  --btn-font: var(--font);
  --btn-font-size: var(--s4);
  --btn-sm-font-size: var(--s6);
  --btn-xs-font-size: var(--s7);
  --btn-xs-padding: 0.5rem 1rem;
  --btn-radius: 0.5rem;

  --header-icon-color: var(--black);
  --header-icon-border: var(--black-lighter);
  --header-icon-bg: var(--bg);

  --heading-color: var(--text);
  --heading-font: var(--font);
}

/* .darkMode {
  --bg: #1b1a1b;
  --bg-lighter: hsl(300, 1%, 20%);
  --text: #e9e9e9;
  --text-black: #222;
  --border-color: var(--lightgrey);
  --box-shadow-color: rgba(0, 0, 0, 0.5);
  --box-shadow-color-lighter: rgba(0, 0, 0, 0.25);
  --box-shadow: 0 5px 5px var(--box-shadow-color);

  --elem-border-color: var(--beige);

  --btn-primary-color: var(--text-black);
  --btn-primary-bg: var(--lime);
  --btn-secondary-color: var(--text-black);
  --btn-secondary-bg: var(--beige);
  --btn-third-color: var(--text-black);
  --btn-third-bg: var(--fucales-lighter);
  --btn-fourth-color: var(--text-black);
  --btn-fourth-bg: var(--beige);
  --btn-fifth-color: var(--text-black);
  --btn-fifth-bg: var(--realgrey-lighter);
  --btn-clear-color: var(--text);

  --header-icon-color: var(--black);
  --header-icon-border: var(--black-lighter);
  --header-icon-bg: var(--bg);

  --heading-color: var(--text);
} */
