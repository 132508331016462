.newCamera {
  width: 100%;
  max-width: 100%;
  min-height: 15.5rem;
  max-height: 70vh;
  aspect-ratio: 13 / 10;
  position: relative;
  margin: 0 auto;
  overflow: hidden;
  border-radius: var(--border-radius);
}

.loadingCamera {
  position: absolute;
  width: 50%;
  top: 50%;
  margin: 0 auto;
  left: 0;
  right: 0;
  text-align: center;
}

.newCamera video {
  border-radius: var(--border-radius);
}
