/* Start Camera section */
section#camera {
  padding: var(--space-md);
}

.cameraContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.canvas-overlay {
  position: absolute;
}

#cameraLoadingOverlay {
  font-size: var(--s3);
  display: flex;
  justify-content: center;
  align-items: center;
}
#cameraErrorOverlay {
  transition: opacity 400ms ease-in-out;
}
#cameraErrorOverlay div {
  display: flex;
  justify-content: center;
  align-items: center;
}
#cameraErrorOverlay p {
  line-height: 1.5;
  font-size: var(--s5);
}

#videoContainer {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 60vh;
  border-radius: var(--border-radius);
}

#videoWrapper {
  position: absolute;
  border-radius: var(--border-radius);
}

#videoContainer #videoWrapper #canvasElement,
#videoContainer #videoWrapper #videoElement {
  height: 100%;
  border-radius: var(--border-radius);
  position: absolute;
}

p#cameraInstructions {
  font-size: var(--s5);
  text-align: center;
}

canvas#canvasElement {
  position: absolute;
  top: 0;
  left: 0;
}

select#videoSource {
  max-width: 100%;
  display: none;
  margin-top: var(--space-sm);
}

/* Emd Camera section */
