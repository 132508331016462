.radio {
}

.radiogroup {
  display: flex;
  align-items: center;
  margin-bottom: var(--space-sm);
}

.radiobutton {
  margin-right: 1rem;
  background-color: transparent;
  border: 2px solid var(--lightgrey-darker);
  display: inline-block;

  border-radius: var(--border-radius-round);
}

.radiobuttonDot {
  border-radius: var(--border-radius-round);
  background-color: transparent;
  width: 1.15rem;
  height: 1.15rem;
  display: block;
  margin: 0.15rem;
}

.radiobutton.checked {
  border-color: var(--beige-darker);
}
.radiobutton.checked .radiobuttonDot {
  background-color: var(--beige-darker);
}

@media (min-width: 767px) {
  .radio {
    display: flex !important;
    align-items: center;
  }

  .radiogroup {
    margin-right: 2rem;
  }
}
