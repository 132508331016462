.bottomSectionContainer {
  position: relative;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: white;
}

.bottomSection {
  position: relative;
  z-index: 10;
  bottom: 0;
  left: 0;
  padding: 1rem 2rem 2rem 2rem;
  width: 100%;
  border-radius: var(--border-radius) var(--border-radius) 0 0;
  box-shadow: -0.25rem 0.25rem 0.25rem var(0, 0, 0, 0.25);
}

.bottomSection.noPadding {
  padding: 0;
}
