.appView {
  height: 100vh;
  padding-bottom: 0rem !important;
  display: grid;
  grid-template-rows: 1fr auto;
}

.view {
  overflow: auto;
}

@media (min-width: 767px) {
  :root {
    font-size: 20px;
  }
}

@media (orientation: landscape) and (min-width: 767px) {
  .appView.cols {
    grid-template-rows: 1fr;
    grid-template-columns: auto min-content;
  }
  .appView.cols > .bottomSection {
    padding-top: 1rem;
    display: flex;
    justify-content: flex-end;
    border-left: 1px solid var(--lightgrey);
  }

  .appView.cols > .bottomSection button {
    width: 17.5rem;
  }

  .grid-column-reset {
    grid-column-start: 1;
  }

  .landscape-w50 {
    width: 50%;
    margin: 0 auto;
  }
}

.viewWithBottomContainer {
  height: 100%;
  display: grid;
  grid-template-rows: 1fr auto;
}
