* {
  margin: 0;
  padding: 0;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}

html {
  font-size: var(--font-normal);
  box-sizing: border-box;
  font-family: var(--font);
  font-weight: var(--font-weight-normal);
}

body {
  background-color: var(--bg);
  width: 100vw;
  height: 100vh;
  position: relative;
  user-select: none;
}

.app {
  height: 100vh;
  width: 100vw;
  overflow: auto;
  background-color: var(--bg);
  color: var(--text);
}

.appView {
  padding-top: 4.5rem;
  padding-bottom: 8rem;
}

h1,
h2 {
  font-size: var(--s3);
}

h3 {
  font-size: var(--s5);
}

h4,
h5,
h6 {
  font-size: var(--s6);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: var(--font-weight-bold);
  color: var(--heading-color);
  font-family: var(--heading-font);
  padding: 0;
  margin-bottom: var(--space-md);
}

p {
  margin-block-start: 0;
  margin-block-end: 0;
  line-height: 1.5;
  margin-bottom: var(--space-md);
}

a,
a:focus,
a:hover,
a:active,
a:visited {
  color: var(--carrot);
  font-weight: var(--font-weight-bold);
  text-decoration: underline;
}

a button,
a:focus button,
a:hover button,
a:active button,
a:visited button {
  text-decoration: none;
}

button {
  display: block;
  appearance: none;
  -webkit-appearance: none;
  white-space: nowrap;
  background-color: var(--btn-primary-bg);
  color: var(--btn-primary-color);
  font-family: var(--btn-font);
  font-size: var(--btn-font-size);
  font-weight: var(--font-weight-bold);
  border-radius: var(--btn-radius);
  border: 0;
  margin-bottom: var(--space-md);

  padding: 1rem;
}

button:disabled {
  opacity: 0.5;
}

button.w100 {
  width: 100%;
}

button.sm {
  font-size: var(--s6);
}

button.xs {
  padding: 0.5rem 1rem;
  font-size: var(--s6);
}

button.primary {
  background-color: var(--btn-primary-bg);
  color: var(--btn-primary-color);
}

button.secondary {
  background-color: var(--btn-secondary-bg);
  color: var(--btn-secondary-color);
}

button.clear {
  background-color: var(--btn-clear-bg);
  color: var(--btn-clear-color);
}

select,
input,
textarea {
  background-color: var(--bg);
  color: var(--text);
  font-family: var(--font);
  border-radius: var(--border-radius-sm);
  border: 2px solid var(--lightgrey-darker);
  font-size: var(--s6);
  margin: 0 0 2rem 0;
  padding: 0.5rem;
  outline: 0;
  min-width: 4rem;
  max-width: 100%;
}

select:focus,
input:focus,
textarea:focus {
  outline: none;
  border-color: var(--lightgrey-darker);
}

select::placeholder,
input::placeholder,
textarea::placeholder {
  color: var(--black-lighter);
}

input:invalid {
  border: 2px dashed var(--danger);
}

textarea {
  display: block;
  font-family: var(--font);
  font-size: var(--s6);
  width: 100%;
  padding: 0.5rem 0.75rem;
  min-height: 7.5rem;
}

select.w100,
input.w100,
textarea.w100 {
  width: 100%;
}

label {
  display: block;
  font-size: var(--s5);
  font-weight: var(--font-weight-bold);
}

/* TEXT */

.f1 {
  font-size: var(--s1);
}
.f2 {
  font-size: var(--s2);
}
.f3 {
  font-size: var(--s3);
}
.f4 {
  font-size: var(--s4);
}
.f5 {
  font-size: var(--s5);
}
.f6 {
  font-size: var(--s6);
}
.f7 {
  font-size: var(--s7);
}
.f8 {
  font-size: var(--s8);
}

.font-receipt {
  font-family: var(--font-receipt);
  font-size: var(--s8);
  color: var(--black-lighter);
}

h1.left,
h2.left,
h3.left,
h4.left,
h5.left,
h6.left,
p.left,
a.left,
label.left,
.text-left {
  text-align: left;
}

h1.center,
h2.center,
h3.center,
h4.center,
h5.center,
h6.center,
p.center,
a.center,
label.center,
.text-center {
  text-align: center;
}

h1.right,
h2.right,
h3.right,
h4.right,
h5.right,
h6.right,
p.right,
a.right,
label.right,
.text-right {
  text-align: right;
}

.strong {
  font-weight: var(--font-weight-bold);
}

h1.fucales,
h2.fucales,
h3.fucales,
h4.fucales,
h5.fucales,
h6.fucales,
p.fucales,
a.fucales,
label.fucales,
.text-fucales {
  color: var(--fucales);
}

h1.carrot,
h2.carrot,
h3.carrot,
h4.carrot,
h5.carrot,
h6.carrot,
p.carrot,
a.carrot,
label.carrot,
.text-carrot {
  color: var(--carrot);
}

h1.--realgrey-darkest,
h2.realgrey-darkest,
h3.realgrey-darkest,
h4.realgrey-darkest,
h5.realgrey-darkest,
h6.realgrey-darkest,
p.realgrey-darkest,
a.realgrey-darkest,
label.realgrey-darkest,
.text-realgrey-darkest {
  color: var(--realgrey-darkest);
}

/* / TEXT */

/* CONTAINERS */

@keyframes slideFromBottom {
  0% {
    transform: translateY(15%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

div.view {
  /*animation: 0.05s ease-out 0s 1 slideFromBottom; */
  padding: var(--space-md);
}

.container {
  margin-bottom: var(--space-md);
}

.container.xs {
  margin-bottom: var(--space-xs);
}

.container.sm {
  margin-bottom: var(--space-sm);
}

.container.sm2 {
  margin-bottom: var(--space-sm2);
}

.container.lg {
  margin-bottom: var(--space-lg);
}

.container.xl {
  margin-bottom: var(--space-xl);
}

.box {
  padding: var(--space-md);
}

.box-sm {
  padding: var(--space-sm);
}

.highlight {
  padding: var(--space-sm);
  margin-bottom: var(--space-md);
  background-color: var(--lightgrey);
  border-radius: var(--border-radius-sm);
}

.seperator {
  margin-bottom: 0 !important;
  border-bottom: 1px solid var(--lightgrey-darker);
}

.seperator-top {
  border-top: 1px solid var(--lightgrey-darker);
}

/* / CONTAINERS */

/* FLEX */

.rows {
  display: flex;
  flex-direction: column;
  margin-bottom: var(--space-md);
}

.rows input,
.rows textarea,
.rows select,
.rows p,
.rows a,
.rows label,
.rows .container,
.rows .box,
.rows .box-sm,
.rows button {
  margin-bottom: 0;
}

.rows > * {
  display: block;
  margin-bottom: var(--space-sm) !important;
}

.rows > :last-child {
  margin-bottom: 0 !important;
}

.rows.center {
  align-items: center;
}

.rows.nospace {
  margin-bottom: 0 !important;
}

.rows > .nospace {
  margin-bottom: 0 !important;
}

.rows.xs {
  margin-bottom: var(--space-xs) !important;
}

.rows.sm2 {
  margin-bottom: var(--space-sm2) !important;
}

.rows.lg {
  margin-bottom: var(--space-lg) !important;
}

.rows.xl {
  margin-bottom: var(--space-xl) !important;
}

.rows-xs > * {
  margin-bottom: var(--space-xs) !important;
}

.rows-sm2 > * {
  margin-bottom: var(--space-sm2) !important;
}

.rows-lg > * {
  margin-bottom: var(--space-lg) !important;
}

.rows-xl > * {
  margin-bottom: var(--space-xl) !important;
}

.columns input,
.columns textarea,
.columns select,
.columns p,
.columns a,
.columns label,
.columns .container,
.columns .box,
.columns .box-sm,
.columns button {
  margin-bottom: 0;
}

.columns {
  display: flex;
  align-items: center;
}

.columns > * {
  display: block;
  margin-right: var(--space-sm);
  margin-bottom: 0;
}

.columns.sm2 > * {
  margin-right: var(--space-sm2);
}

.columns.md > * {
  margin-right: var(--space-md);
}

.columns.lg > * {
  margin-right: var(--space-lg);
}

.columns.xl > * {
  margin-right: var(--space-xl);
}

.columns > :last-child {
  margin-right: 0;
}

.columns.center {
  justify-content: center;
}

.columns.stretch > * {
  flex: 1;
}

.columns > .space-between {
  margin-left: auto;
}

.columns.nospace {
  margin-right: 0 !important;
}

.columns > .nospace {
  margin-right: 0 !important;
}

.grid {
  display: grid;
}

.grid.sm {
  display: grid;
  column-gap: var(--space-sm);
  row-gap: var(--space-sm);
}

.grid.column-sm {
  display: grid;
  column-gap: var(--space-sm);
}

.grid.column-xs {
  display: grid;
  column-gap: var(--space-xs);
}

.grid.column-xss {
  display: grid;
  column-gap: var(--space-xss);
}

.grid.row-xs {
  display: grid;
  row-gap: var(--space-xs);
}

.grid.row-xxs {
  display: grid;
  row-gap: var(--space-xxs);
}

.grid-c2 {
  grid-template-columns: 1fr 1fr;
}

.grid-c3 {
  grid-template-columns: 1fr 1fr 1fr;
}

.grid-c4 {
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.grid-r2 {
  grid-template-rows: 1fr 1fr;
}

.grid-r3 {
  grid-template-rows: 1fr 1fr 1fr;
}

.grid-center-h {
  justify-items: center;
}

.grid-center-v {
  align-items: center;
}

.grid-self-center {
  align-self: center;
  justify-self: center;
}

.grid-self-right {
  justify-self: flex-end;
}

.middle {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* / FLEX */

/* COLOR */

.danger {
  color: var(--danger);
}

/* /COLOR */

/* POPUPCONTAINER */

.popupWrapper {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 13;
  background-color: rgba(255, 255, 255, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.popupContainer {
  width: fit-content;
  height: fit-content;
  background-color: var(--bg);
  border-radius: var(--border-radius);
  padding: var(--space-md);
  box-shadow: 0 5px 5px var(--box-shadow-color);
  width: 80%;
  text-align: center;
}

.popupContainer button {
  margin-bottom: 0;
}

/* / POPUPCONTAINER */
